// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.revolving__container {
  position: absolute;
  width: 100vw;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.6s linear;
}
.revolving__container .text {
  padding: 0;
  color: #AAA;
  font-size: 0.6em;
  padding-right: 140px;
  letter-spacing: 5px;
  font-weight: 300;
}
@media only screen and (max-width: 670px) {
  .revolving__container .text {
    font-size: 0.5em;
    padding-right: 120px;
  }
}
.revolving__container p {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}
.revolving__container .word {
  position: absolute;
  top: 1px;
  opacity: 0;
  color: #333;
  font-weight: 400;
  transform: translateZ(25px);
  transform-origin: 50% 50% 25px;
}
.revolving__container .word.out {
  transform: rotateX(90deg);
  transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.revolving__container .word.behind {
  transform: rotateX(-90deg);
}
.revolving__container .word.in {
  transform: rotateX(0deg);
  transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
@media only screen and (max-width: 670px) {
  .revolving__container {
    bottom: calc(80vh);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/RevolvingText/RevolvingText.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;EACA,+BAAA;AACF;AAPA;EASM,UAAA;EACA,WAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;AACN;AAEE;EAAA;IAEI,gBAAA;IACA,oBAAA;EAAJ;AACF;AArBA;EAyBI,qBAAA;EACA,mBAAA;EACA,SAAA;AADJ;AA1BA;EA+BI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,gBAAA;EACA,2BAAA;EACA,8BAAA;AAFJ;AAnCA;EAyCI,yBAAA;EACA,kEAAA;AAHJ;AAvCA;EA8CI,0BAAA;AAJJ;AA1CA;EAkDI,wBAAA;EACA,mEAAA;AALJ;AASA;EACE;IACE,kBAAA;EAPF;AACF","sourcesContent":[".revolving__container {\n  position: absolute;\n  width: 100vw;\n  display: flex;\n  flex-direction: column;\n  opacity: 1;\n  transition: opacity .6s linear;\n\n  .text {\n      padding: 0;\n      color: #AAA;\n      font-size: .6em;\n      padding-right: 140px;\n      letter-spacing: 5px;\n      font-weight: 300;\n  }\n\n  @media only screen and (max-width: 670px) {\n    .text {\n      font-size: .5em;\n      padding-right: 120px;\n    }\n  }\n\n  p {\n    display: inline-block;\n    vertical-align: top;\n    margin: 0;\n  }\n\n  .word {\n    position: absolute;\n    top: 1px;\n    opacity: 0;\n    color: #333;\n    font-weight: 400;\n    transform: translateZ(25px);\n    transform-origin: 50% 50% 25px;\n  }\n\n  .word.out {\n    transform: rotateX(90deg);\n    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);\n  }\n\n  .word.behind {\n    transform: rotateX(-90deg);\n  }\n\n  .word.in {\n    transform: rotateX(0deg);\n    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n  }\n}\n\n@media only screen and (max-width: 670px) {\n  .revolving__container {\n    bottom: calc(80vh); \n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
